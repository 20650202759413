import Vue from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './i18n'
import AOS from 'aos';
import "aos/dist/aos.css";
import DataService from './services/DataService.js';
import Axios from 'axios';

Vue.config.productionTip = false;
Axios.defaults.baseURL = 'http://hroof.germanywestcentral.azurecontainer.io:1337';
// Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
Vue.prototype.$http = DataService;

// use beforeEach route guard to set the language
router.beforeEach((to, from, next) => {

    // use the language from the routing param or default language
    let language = to.params.lang;
    if (!language) {
        language = 'en'
    }

    // set the current language for i18n.
    i18n.locale = language
    next()
})

new Vue({
    router,
    i18n,
    created() {
        AOS.init();
    },
    render: h => h(App),
}).$mount('#app')