<template>
  <div
    id="app"
    class=""
    :dir="`${$i18n.locale}` == 'ar' ? 'rtl' : 'ltr'"
  >
    <Navbar class="z-50" />
    <router-view class="z-0"  />
    <Footer />
  </div>
</template>
<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer2.vue";
export default {
  components: {
    Navbar,
    Footer,
  },
  data() {
    return {};
  },
  mounted() {},
};
</script>

<style src='./assets/css/tailwind.css'></style>

<style>
  html {
  scroll-behavior: smooth;
}
  @font-face {
    font-family: 'AktivGrotesk_Trial_Rg';
    src: url("~@/assets/fonts/arabic/AktivGroteskCorp-Regular.ttf");
  }
  body {
    font-family: 'AktivGrotesk_Trial_Rg'
  }

  @font-face {
    font-family: 'AktivGrotesk_Trial_Rg';
    src: url("~@/assets/fonts/AktivGrotesk_Trial_Rg.ttf");
  }
  .fontRg {
    font-family: 'AktivGrotesk_Trial_Rg'
  }

  @font-face {
    font-family: 'AktivGrotesk_Trial_Lt';
    src: url("~@/assets/fonts/AktivGrotesk_Trial_Lt.ttf");
  }
  .fontLt {
    font-family: 'AktivGrotesk_Trial_Lt'
  }


</style>
