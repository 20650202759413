<template>
  <div class="home">
    <button @click="toggleMute1">
      <div :data-muted="isMuted">
        <video
          ref="video"
          src="../assets/images/HROOFSHOWREEL.mp4"
          playsinline
          loop
          autoplay
          muted
        ></video>
      </div>
    </button>
   <section class="max-w-8xl mx-auto px-6 xl:px-12">
      <div class="md:flex w-full mt-10 lg:mt-16">
        <div
          class="md:w-1/2 relative"
          :class="`${$i18n.locale}` == 'ar' ? 'md:ml-6' : 'md:mr-6'"
          @mouseover="mouseover(1)"
          @mouseleave="mouseleave(1)"
        >
          <router-link :to="`/${$i18n.locale}/${Section1Left.linke}`">
            <span
              :ref="1"
              :class="`${$i18n.locale}` == 'ar' ? 'left-0 ' : 'right-0'"
              class="
                text-4xl text-white
                font-bold
                absolute
                w-full
                h-full
                bg-gray bg-opacity-75
                hidden
              "
            >
              <h2
                :class="`${$i18n.locale}` == 'ar' ? 'mr-5 mt-5 ' : 'mr-5 mt-5'"
                class="font-bold text-4xl mt-10 ml-12"
              >
                {{ Section1Left.NameProject }}
              </h2>
              <h2
                :class="`${$i18n.locale}` == 'ar' ? 'mr-5 mt-5 ' : 'mr-5 mt-5'"
                class="font-light w-1/2 text-xl ml-12"
              >
                {{ Section1Left.DescriptionProject }}
              </h2>
            </span>
            <img
              :src="Section1Left.images[randomImage]"
              class="object-cover"
              alt=""
            />
          </router-link>
        </div>
        <div
          class="md:w-1/2 relative mt-4 md:mt-0"
          :class="`${$i18n.locale}` == 'ar' ? 'md:mr-6' : 'md:ml-6'"
          @mouseover="mouseover(2)"
          @mouseleave="mouseleave(2)"
        >
          <router-link :to="`/${$i18n.locale}/${Section1Right.linke}`">
            <span
              :ref="2"
              class="
                text-4xl text-white
                font-bold
                absolute
                w-full
                h-full
                bg-gray bg-opacity-75
                hidden
              "
            >
              <h2
                :class="`${$i18n.locale}` == 'ar' ? 'mr-5 mt-5 ' : 'mr-5 mt-5'"
                class="font-bold text-4xl mt-10 ml-12"
              >
                {{ Section1Right.NameProject }}
              </h2>
              <h2
                :class="`${$i18n.locale}` == 'ar' ? 'mr-5 mt-5 ' : 'mr-5 mt-5'"
                class="font-light w-1/2 text-xl ml-12"
              >
                {{ Section1Right.DescriptionProject }}
              </h2>
            </span>
            <img
              :src="Section1Right.images[randomImage]"
              class="object-cover"
              alt=""
            />
          </router-link>
        </div>
      </div>
      <div
        class="mt-4 md:mt-12 relative"
        @mouseover="mouseover(3)"
        @mouseleave="mouseleave(3)"
      >
        <router-link :to="`/${$i18n.locale}/${Section2.linke}`">
          <span
            :ref="3"
            class="
              text-white
              absolute
              w-full
              h-full
              bg-gray bg-opacity-75
              hidden
            "
            ><h2
              :class="
                `${$i18n.locale}` == 'ar' ? 'mr-12 mt-10 ' : 'mr-12 mt-10'
              "
              class="font-bold text-4xl mt-10 ml-12"
            >
              {{ Section2.NameProject }}
            </h2>
            <h2
              :class="`${$i18n.locale}` == 'ar' ? 'mr-12  ' : 'mr-12'"
              class="font-light w-1/4 text-xl ml-12"
            >
              {{ Section2.DescriptionProject }}
            </h2>
          </span>
          <img
            :src="Section2.images[randomImage]"
            alt=""
            class="object-cover"
          />
        </router-link>
      </div>
      <div class="md:flex w-full mt-4 md:mt-12">
        <div
          class="md:w-1/2 relative"
          :class="`${$i18n.locale}` == 'ar' ? 'md:ml-6' : 'md:mr-6'"
          @mouseover="mouseover(4)"
          @mouseleave="mouseleave(4)"
        >
          <router-link :to="`/${$i18n.locale}/${Section3Left.linke}`">
            <span
              :ref="4"
              class="
                text-4xl text-white
                font-bold
                absolute
                w-full
                h-full
                bg-gray bg-opacity-75
                hidden
              "
              ><h2
                :class="`${$i18n.locale}` == 'ar' ? 'mr-5 mt-5 ' : 'mr-5 mt-5'"
                class="font-bold text-4xl mt-10 ml-12"
              >
                {{ Section3Left.NameProject }}
              </h2>
              <h2
                :class="`${$i18n.locale}` == 'ar' ? 'mr-5 mt-5 ' : 'mr-5 mt-5'"
                class="font-light w-1/2 text-xl ml-12"
              >
                {{ Section3Left.DescriptionProject }}
              </h2>
            </span>
            <img
              :src="Section3Left.images[randomImage]"
              alt=""
              class="object-cover"
            />
          </router-link>
        </div>
        <div
          class="md:w-1/2 relative mt-4 md:mt-0"
          :class="`${$i18n.locale}` == 'ar' ? 'md:mr-6' : 'md:ml-6'"
          @mouseover="mouseover(5)"
          @mouseleave="mouseleave(5)"
        >
          <router-link :to="`/${$i18n.locale}/${Section3Right.linke}`">
            <span
              :ref="5"
              class="
                text-4xl text-white
                font-bold
                absolute
                w-full
                h-full
                bg-gray bg-opacity-75
                hidden
              "
              ><h2
                :class="`${$i18n.locale}` == 'ar' ? 'mr-5 mt-5 ' : 'mr-5 mt-5'"
                class="font-bold text-4xl mt-10 ml-12"
              >
                {{ Section3Right.NameProject }}
              </h2>
              <h2
                :class="`${$i18n.locale}` == 'ar' ? 'mr-5 mt-5 ' : 'mr-5 mt-5'"
                class="font-light w-1/2 text-xl ml-12"
              >
                {{ Section3Right.DescriptionProject }}
              </h2>
            </span>
            <img
              :src="Section3Right.images[randomImage]"
              alt=""
              class="object-cover"
            />
          </router-link>
        </div>
      </div>
      <div
        class="mt-4 md:mt-12 relative"
        @mouseover="mouseover(6)"
        @mouseleave="mouseleave(6)"
      >
        <router-link
          :to="`/${$i18n.locale}/${Section4.linke}`"
          class="mt-6"
          data-aos="fade-up"
          data-aos-delay="600"
        >
          <span
            :ref="6"
            class="
              text-4xl text-white
              font-bold
              absolute
              w-full
              h-full
              bg-gray bg-opacity-75
              hidden
            "
            ><h2
              :class="`${$i18n.locale}` == 'ar' ? 'mr-5 mt-5 ' : 'mr-5 mt-5'"
              class="font-bold text-4xl mt-10 ml-12"
            >
              {{ Section4.NameProject }}
            </h2>
            <h2
              :class="`${$i18n.locale}` == 'ar' ? 'mr-5 mt-5 ' : 'mr-5 mt-5'"
              class="font-light w-1/2 text-xl ml-12"
            >
              {{ Section4.DescriptionProject }}
            </h2>
          </span>

          <img :src="Section4.images[randomImage]" alt="" />
        </router-link>
      </div>
      <div class="flex items-center justify-center w-full">
        <router-link
          class="
            px-12
            py-2
            text-xl
            lg:text-3xl
            text-center
            fontRg
            text-footer
            my-2
            hover:font-bold
          "
          :to="`/${$i18n.locale}/almadar`"
        >
          {{ $t("Home.link") }}
        </router-link>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loaded: false,
      blacknav: this.$route.path,
       Local: this.$i18n.locale,
      SliderImage: [],
      randomImage: 0,
      Section1Left: {
        NameProject: "",
        DescriptionProject: "",
        linke: "",
        images: [],
      },
      Section1Right: {
        NameProject: "",
        DescriptionProject: "",
        linke: "",
        images: [],
      },
      Section2: {
        NameProject: "",
        DescriptionProject: "",
        linke: "",
        images: [],
      },
      Section3Left: {
        NameProject: "",
        DescriptionProject: "",
        linke: "",
        images: [],
      },
      Section3Right: {
        NameProject: "",
        DescriptionProject: "",
        linke: "",
        images: [],
      },
      Section4: {
        NameProject: "",
        DescriptionProject: "",
        linke: "",
        images: [],
      },
      isMuted: true,
     };
  },
  created() {
    scrollTo(0, 0);
  },
  mounted() {
    this.fetchData();

  },
  methods: {
  
    toggleMute1() {
      var vid = this.$refs.video;
      vid.muted = !vid.muted;
    },
    async fetchData() {
    await this.HomeSection1Left();
    await this.HomeSection1Right();
    await this.HomeSection2();
    await this.HomeSection3Left();
    await this.HomeSection3Right();
    await this.HomeSection4();
    this.loaded = true;
    },
    RandomSlider(images) {
      this.randomImage = Math.floor(Math.random() * images.length);
    },
    mouseover(id) {
      let target = id;
      this.$refs[target].style.display = "block";
    },
    mouseleave(id) {
      let target = id;
      this.$refs[target].style.display = "none";
    },
    HomeSection1Left() {
      this.$http.homeService
        .HomeSection1Left(this.Local)
        .then((res) => {
          (this.Section1Left.NameProject = res.data.NameProject),
            (this.Section1Left.linke = res.data.linke),
            (this.Section1Left.DescriptionProject =
              res.data.DescriptionProject);
          let images = res.data.Images;
          for (let i = 0; i < images.length; i++) {
            let obj = {};
            obj["image"] = `http://hroof.germanywestcentral.azurecontainer.io:1337${images[i].url}`;
            this.Section1Left.images.push(obj["image"]);
          }
          this.RandomSlider(this.Section1Left.images);
        })
        .catch((err) => {
          this.addErorr = err;
        });
    },
    HomeSection1Right() {
      this.$http.homeService
        .HomeSection1Right(this.Local)
        .then((res) => {
          (this.Section1Right.NameProject = res.data.NameProject),
            (this.Section1Right.linke = res.data.linke),
            (this.Section1Right.DescriptionProject =
              res.data.DescriptionProject);
          let images = res.data.Images;
          for (let i = 0; i < images.length; i++) {
            let obj = {};
            obj["image"] = `http://hroof.germanywestcentral.azurecontainer.io:1337${images[i].url}`;
            this.Section1Right.images.push(obj["image"]);
          }
          this.RandomSlider(this.Section1Right.images);
        })
        .catch((err) => {
          this.addErorr = err;
        });
    },
    HomeSection2() {
      this.$http.homeService
        .HomeSection2(this.Local)
        .then((res) => {
          (this.Section2.NameProject = res.data.NameProject),
            (this.Section2.linke = res.data.linke),
            (this.Section2.DescriptionProject = res.data.DescriptionProject);
          let images = res.data.Images;
          for (let i = 0; i < images.length; i++) {
            let obj = {};
            obj["image"] = `http://hroof.germanywestcentral.azurecontainer.io:1337${images[i].url}`;
            this.Section2.images.push(obj["image"]);
          }
          this.RandomSlider(this.Section2.images);
        })
        .catch((err) => {
          this.addErorr = err;
        });
    },
    HomeSection3Left() {
      this.$http.homeService
        .HomeSection3Left(this.Local)
        .then((res) => {
          (this.Section3Left.NameProject = res.data.NameProject),
            (this.Section3Left.linke = res.data.linke),
            (this.Section3Left.DescriptionProject =
              res.data.DescriptionProject);
          let images = res.data.Images;
          for (let i = 0; i < images.length; i++) {
            let obj = {};
            obj["image"] = `http://hroof.germanywestcentral.azurecontainer.io:1337${images[i].url}`;
            this.Section3Left.images.push(obj["image"]);
          }
          this.RandomSlider(this.Section3Left.images);
        })
        .catch((err) => {
          this.addErorr = err;
        });
    },
    HomeSection3Right() {
      this.$http.homeService
        .HomeSection3Right(this.Local)
        .then((res) => {
          (this.Section3Right.NameProject = res.data.NameProject),
            (this.Section3Right.linke = res.data.linke),
            (this.Section3Right.DescriptionProject =
              res.data.DescriptionProject);
          let images = res.data.Images;
          for (let i = 0; i < images.length; i++) {
            let obj = {};
            obj["image"] = `http://hroof.germanywestcentral.azurecontainer.io:1337${images[i].url}`;
            this.Section3Right.images.push(obj["image"]);
          }
          this.RandomSlider(this.Section3Right.images);
        })
        .catch((err) => {
          this.addErorr = err;
        });
    },
    HomeSection4() {
      this.$http.homeService
        .HomeSection4(this.Local)
        .then((res) => {
          (this.Section4.NameProject = res.data.NameProject),
            (this.Section4.linke = res.data.linke),
            (this.Section4.DescriptionProject = res.data.DescriptionProject);
          let images = res.data.Images;
          for (let i = 0; i < images.length; i++) {
            let obj = {};
            obj["image"] = `http://hroof.germanywestcentral.azurecontainer.io:1337${images[i].url}`;
            this.Section4.images.push(obj["image"]);
          }
          this.RandomSlider(this.Section4.images);
        })
        .catch((err) => {
          this.addErorr = err;
        });
    },
  },
};
</script>
<style></style>
