import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import i18n from '../i18n'
Vue.use(VueRouter)

const routes = [{
        path: '/',
        redirect: `/${i18n.locale}`
    },
    {
        path: '/:lang',
        component: {
            render(c) { return c('router-view') }
        },
        children: [{
                path: '',
                name: 'home',
                component: Home
            }, {
                path: 'covid-19',
                name: 'covid-19',
                component: () =>
                    import ('../views/Covid-19.vue')
            },
            {
                path: 'culture',
                name: 'culture',
                component: () =>
                    import ('../views/Culture.vue')
            },
            {
                path: 'training',
                name: 'training',
                component: () =>
                    import ('../views/Training.vue')
            },
            {
                path: 'job',
                name: 'job',
                component: () =>
                    import ('../views/Job.vue')
            },
            {
                path: 'about',
                name: 'about',
                component: () =>
                    import ('../views/About.vue')
            },
            {
                path: 'contact',
                name: 'contact',
                component: () =>
                    import ('../views/Contact.vue')
            },
            {
                path: 'people',
                name: 'people',
                component: () =>
                    import ('../views/People.vue')
            },
            {
                path: 'almadar',
                name: 'almadar',
                component: () =>
                    import ('../views/5G.vue')
            },
            {
                path: 'abnaYousef',
                name: 'abnaYousef',
                component: () =>
                    import ('../views/AbnaYousef.vue')
            },
            {
                path: 'anharAlkhair',
                name: 'anharAlkhair',
                component: () =>
                    import ('../views/AnharAlkhair.vue')
            },
            {
                path: 'dima',
                name: 'dima',
                component: () =>
                    import ('../views/Dima.vue')
            },
            {
                path: 'kidsAcademy',
                name: 'kidsAcademy',
                component: () =>
                    import ('../views/kidsAcademy.vue')
            },
            {
                path: 'Dwaya',
                name: 'Dwaya',
                component: () =>
                    import ('../views/Dwaya.vue')
            },
            {
                path: 'Tadawul',
                name: 'Tadawul',
                component: () =>
                    import ('../views/Tadawul.vue')
            }
        ]
    },
    {
        path: '*',
        component: () =>
            import ('../views/NotFound.vue')
    },
]

const router = new VueRouter({
    mode: 'history',
    routes
})

export default router